<template>
  <section>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                Documents
                <span class="d-block text-muted pt-2 font-size-sm"
                  >lalal tralalala n2jaky textik</span
                >
              </h3>
            </div>

            <div class="card-toolbar">
              <!--begin::Button-->

              <v-flex class="mr-2">
                <collection_select />
              </v-flex>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    :headers="listHeaders"
                    :items="documents"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          placeholder="Search in documents"
                          label="Search"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <router-link
                        :to="{
                          name: 'trending-item',
                          query: { id: item.id }
                        }"
                      >
                        {{ item.name }}
                      </router-link>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PULL_DOCUMENTS } from "../../../core/services/store/documents.module";
import { mapGetters, mapState } from "vuex";
import collection_select from "@/view/pages/collections/collection_select";

export default {
  components: { collection_select },

  data() {
    return {
      search: "",
      options: {}
    };
  },
  methods: {
    reloadData() {
      if (this.selectedCollection && this.selectedCollection.id > 0) {
        this.$store.dispatch(PULL_DOCUMENTS, this.selectedCollection);
      }
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapState({
      errors: state => state.documents.errors,
      message: state => state.documents.message,
      hasMessage: state => state.documents.hasMessage,
      documents: state => state.documents.documents,
      headers: state => state.documents.headers,
      loading: state => state.documents.loading
    }),
    ...mapGetters(["selectedCollection", "currentProject"]),
    listHeaders() {
      return Array.from(this.headers).map(a => {
        return {
          text: a.toUpperCase(),
          value: a
        };
      });
    }
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documents" }]);
  }
};
</script>
